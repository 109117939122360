import React from 'react'
import Page from '../components/Page'

const PatentTranslationPage = () => (
    <Page title="Patent Translation" description="Patent Translation">
      <p className="mt-8 w-2/3 text-center">
          Patent translations are clear, precise, technologically correct, and legally binding
          translations. Our technical linguists understand that words of originals and translations tremendously and significantly impact the scope of protection of IP
          rights.<br/>
          <br/>
          With professional background in engineering and natural sciences our certified
          translators provide patent translations in nine languages.
          The target language is always the translator’s mother tongue.<br/>
          <br/>
          We have decades of experience in this area (since 2003) and can provide review,
          correction, certification, reports, and expert witness services, as necessary.
      </p>
    </Page>
)

export default PatentTranslationPage